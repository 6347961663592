import Header from "./components/Header";
import Main from "./components/Main";
import Content from "./components/Content";
import { useEffect } from "react";
import OneSignal from "react-onesignal";

const ONESIGNAL_APP_ID = "b5ceda27-54ac-48d2-98c3-38c170c9f508";

function App() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/OneSignalSDKWorker.js")
      .then((registration) => {
        console.log("Service Worker registered:", registration);
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  }
  const initOneSignal = async () => {
    await OneSignal.init({
      appId: ONESIGNAL_APP_ID,
      serviceWorkerPath: "/OneSignalSDKWorker.js",
    });

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/OneSignalSDKWorker.js")
        .then((reg) => console.log("OneSignal SW зарегистрирован!", reg.scope))
        .catch((err) => console.error("Ошибка регистрации OneSignal SW:", err));
    }

    OneSignal.showSlidedownPrompt();
  };

  useEffect(() => {
    initOneSignal();
  }, []);

  return (
    <div>
      <Header />
      <Main />
      <Content />
      {/* <button onClick={handleInstallClick}>📲 Установить PWA</button> */}
    </div>
  );
}

export default App;
