import React from "react";

const Content = () => {
  return (
    <>
      <div className="content_wrapper__45t+V">
        <div className="content_wrapperContent__CC1qD">
          <div className="content_screens_wrapper__YeeJ3">
            <div className="content_screens_wList__fbowt">
              {["408", "409", "410", "411"].map((num) => (
                <div className="content_screens_listItem__TasxL" key={num}>
                  <div className="content_screens_wImg__vJV4o">
                    <picture>
                      <source
                        srcSet={`/_r0x${num}_webp.webp`}
                        type="image/webp"
                      />
                      <source srcSet={`/_r0x${num}_jpg`} type="image/jpeg" />
                      <img alt="screen" src={`/_r0x${num}_webp.webp`} />
                    </picture>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <section className="DescSelector_sel__TiLso">
            <header className="DescSelector_header__kzxq+">
              <div className="DescSelector_hTop__iz3Wu">
                <div className="DescSelector_hTopT__lQ46T">
                  <h2>Kuhusu mchezo huu</h2>
                </div>
                <div>
                  <button className="DescSelector_hBtn__2tAOY">
                    <i
                      className="google-material-icons DescSelector_hBtnIcon__lZHve"
                      aria-hidden="true"
                    >
                      arrow_forward
                    </i>
                  </button>
                </div>
              </div>
            </header>
            <div className="DescSelector_content__vd-g4">
              <div className="content_desc_desc__2iBCe">
                **Gal Sport Casino: Furahia Ushindi Mkubwa!** Karibu kwenye Gal
                Sport Casino, jukwaa bora la michezo ya kubahatisha ambalo
                linakupa nafasi ya kushinda zawadi kubwa kila siku! Furahia
                buruda
              </div>
              <div className="content_desc_lastUpdate__+V7ZD">
                <div>
                  <div className="content_desc_lastUpdateT__HgO8Q">
                    Sasisho la mwisho
                  </div>
                  <div className="content_desc_lastUpdateD__5pu-m">
                    Tarehe 2 Agosti 2024
                  </div>
                </div>
              </div>
              <div className="content_desc_tags__94Tv6"></div>
            </div>
          </section>
          <section className="DescSelector_sel__TiLso">
            <header className="DescSelector_header__kzxq+">
              <div className="DescSelector_hTop__iz3Wu">
                <div className="DescSelector_hTopT__lQ46T">
                  <h2>Usalama wa data</h2>
                </div>
                <div>
                  <button className="DescSelector_hBtn__2tAOY">
                    <i
                      className="google-material-icons DescSelector_hBtnIcon__lZHve"
                      aria-hidden="true"
                    >
                      arrow_forward
                    </i>
                  </button>
                </div>
              </div>
            </header>
            <div className="DescSelector_content__vd-g4">
              Ili kudhibiti usalama, unahitaji kujua jinsi wasanidi programu
              kukusanya data yako na kuihamisha kwa wahusika wengine. Mbinu
              kuhakikisha usalama na faragha inaweza kutegemea jinsi unavyotumia
              programu, pamoja na eneo lako na umri. Taarifa hapa chini
              imetolewa na msanidi programu na katika siku zijazo inaweza
              kubadilika.
              <div className="content_security_bWrapper__RbzB2">
                <div className="content_security_secItem__J-ttM">
                  <img
                    src="/link-fp.webp"
                    srcset="/link-fp-x2.webp 2x"
                    alt="icon"
                  />
                  <div>
                    Data haihamishwi kwa wahusika wengine.
                    <div className="content_security_secItemM__BLD9K">
                      Soma zaidi kuhusu{" "}
                      <span>jinsi wasanidi wanadai uhamishaji wa data</span>…
                    </div>
                  </div>
                </div>
                <div className="content_security_secItem__J-ttM">
                  <img
                    src="/cloud.webp"
                    srcset="/cloud-x2.webp 2x"
                    alt="icon"
                  />
                  <div>
                    Hakuna data inayokusanywa
                    <div className="content_security_secItemM__BLD9K">
                      Soma zaidi kuhusu{" "}
                      <span>jinsi wasanidi wanadai uhamishaji wa data</span>…
                    </div>
                  </div>
                </div>
                <div>
                  <div className="content_security_moreW__ekeBw">
                    <div className="content_security_moreW2__lh3Lo">
                      <span className="content_security_moreT__4emtL">
                        Soma zaidi
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="DescSelector_sel__TiLso">
            <header className="DescSelector_header__kzxq+">
              <div className="DescSelector_hTop__iz3Wu">
                <div className="DescSelector_hTopT__lQ46T">
                  <h2>Ukadiriaji na hakiki</h2>
                </div>
                <div>
                  <button className="DescSelector_hBtn__2tAOY">
                    <i
                      className="google-material-icons DescSelector_hBtnIcon__lZHve"
                      aria-hidden="true"
                    >
                      arrow_forward
                    </i>
                  </button>
                </div>
              </div>
            </header>
            <div className="DescSelector_content__vd-g4">
              <div className="Content_comments_rating_wrapperRating__linmK">
                <div className="Content_comments_rating_wrapperRatingS2__lVLCJ">
                  <div className="Content_comments_rating_wrapperRatingS3__u6Z0l">
                    <div>
                      <div className="Content_comments_rating_ratingTitle__Xp3IS">
                        4,8
                      </div>
                      <div className="StarRating_starList__vWMsK">
                        <span className="StarRating_starItem__+EBtP">
                          <span className="StarRating_starItemFill__+2irG">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                            </svg>
                          </span>
                        </span>
                        <span className="StarRating_starItem__+EBtP">
                          <span className="StarRating_starItemFill__+2irG">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                            </svg>
                          </span>
                        </span>
                        <span className="StarRating_starItem__+EBtP">
                          <span className="StarRating_starItemFill__+2irG">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                            </svg>
                          </span>
                        </span>
                        <span className="StarRating_starItem__+EBtP">
                          <span className="StarRating_starItemFill__+2irG">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                            </svg>
                          </span>
                        </span>
                        <span className="StarRating_starItem__+EBtP">
                          <span>
                            <svg
                              className="xdPrrd"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="url(#c2906)"
                            >
                              <defs>
                                <linearGradient id="c2906">
                                  <stop
                                    stop-opacity="1"
                                    offset="80%"
                                    className="StarRating_starItemFill__+2irG"
                                  ></stop>
                                  <stop
                                    stop-opacity="1"
                                    offset="20%"
                                    className="StarRating_starItemNotFill__ymUQS"
                                  ></stop>
                                </linearGradient>
                              </defs>
                              <path d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                            </svg>
                          </span>
                        </span>
                      </div>
                      <div className="Content_comments_rating_review__Yc03t">
                        Mapitio elfu 4.5
                      </div>
                    </div>
                    <div>
                      <div className="Content_comments_rating_lineW__OCAF5">
                        <div className="Content_comments_rating_lineNum__EGTtX">
                          5
                        </div>
                        <div className="Content_comments_rating_lineBase__ohC62">
                          <div
                            className="Content_comments_rating_lineBaseFill__PFHuw"
                            style={{ width: "87%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="Content_comments_rating_lineW__OCAF5">
                        <div className="Content_comments_rating_lineNum__EGTtX">
                          4
                        </div>
                        <div className="Content_comments_rating_lineBase__ohC62">
                          <div
                            className="Content_comments_rating_lineBaseFill__PFHuw"
                            style={{ width: "49%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="Content_comments_rating_lineW__OCAF5">
                        <div className="Content_comments_rating_lineNum__EGTtX">
                          3
                        </div>
                        <div className="Content_comments_rating_lineBase__ohC62">
                          <div
                            className="Content_comments_rating_lineBaseFill__PFHuw"
                            style={{ width: "1%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="Content_comments_rating_lineW__OCAF5">
                        <div className="Content_comments_rating_lineNum__EGTtX">
                          2
                        </div>
                        <div className="Content_comments_rating_lineBase__ohC62">
                          <div
                            className="Content_comments_rating_lineBaseFill__PFHuw"
                            style={{ width: "0%" }}
                          ></div>
                        </div>
                      </div>
                      <div className="Content_comments_rating_lineW__OCAF5">
                        <div className="Content_comments_rating_lineNum__EGTtX">
                          1
                        </div>
                        <div className="Content_comments_rating_lineBase__ohC62">
                          <div
                            className="Content_comments_rating_lineBaseFill__PFHuw"
                            style={{ width: "0%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content_comments_commW__KS6qP">
              <header>
                <div className="content_comments_cHTom__-3xre">
                  <div className="content_comments_cHTomLeft__Uz2TX">
                    <picture>
                      <source srcset="/_r64x64_webp.webp" type="image/webp" />
                      <source srcset="/_r64x64_webp.webp" type="image/jpeg" />
                      <img
                        className="content_comments_commAuthImg__VOqdH"
                        src="/_r64x64_webp.webp"
                        alt="auth"
                      />
                    </picture>
                    <div className="content_comments_commAuth__x9rNc">Juma</div>
                  </div>
                  <div>
                    <div className="content_comments_cHTomRight__35SLt">
                      <div>
                        <button className="content_comments_commBtn__eKEkm">
                          <i className="google-material-icons">more_vert</i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content_comments_ratingW__Icdsj">
                  <div className="StarRating_starList__vWMsK">
                    <span
                      className="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span className="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      className="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span className="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      className="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span className="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      className="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span className="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      className="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span className="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                  </div>
                  <span className="content_comments_commDate__6Wi+t">
                    17 Februari 2025
                  </span>
                </div>
              </header>
              <div className="content_comments_commBW__Tqqwl">
                Niliweka amana yangu ya kwanza na ndani ya saa chache nilishinda
                kiasi kikubwa. Ninapendekeza kila mtu ajaribu!
              </div>
              <div>
                <div className="content_comments_likeB__A+YQO">
                  Watu 46 walipata ukaguzi huu kuwa muhimu.
                </div>
                <footer className="content_comments_likeF__27Irc">
                  <div className="content_comments_likeFInfo__zWZUk">
                    Maoni haya yamekusaidia?
                  </div>
                  <div>
                    <div className="content_comments_likeFBtnWrap__yjgrs">
                      <div className="content_comments_likeFBtn__9gQqp">
                        <div aria-hidden="true">
                          <span>Ndiyo</span>
                        </div>
                      </div>
                      <div className="content_comments_likeFBtn__9gQqp">
                        <div aria-hidden="true">
                          <span>Hapana</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
              <div className="content_comments_resWrapper__NuvTU">
                <div className="content_comments_resTop__zTCTx">
                  <div className="content_comments_resAuth__RZ6JW">
                    Slottica™
                  </div>
                  <div className="content_comments_resDate__vhI07">
                    17 Februari 2025
                  </div>
                </div>
                <div className="content_comments_resComm__jaZuR">
                  <div>Asante kwa maoni yako! Tunathamini maoni yako.</div>
                </div>
              </div>
            </div>
            <div class="content_comments_commW__KS6qP">
              <header>
                <div class="content_comments_cHTom__-3xre">
                  <div class="content_comments_cHTomLeft__Uz2TX">
                    <picture>
                      <source
                        srcset="/870bfde4e2a8be24e8d7a749da66eefb.jpg"
                        type="image/webp"
                      />
                      <source
                        srcset="/870bfde4e2a8be24e8d7a749da66eefb.jpg"
                        type="image/jpeg"
                      />
                      <img
                        class="content_comments_commAuthImg__VOqdH"
                        src="/870bfde4e2a8be24e8d7a749da66eefb.jpg"
                        alt="auth"
                      />
                    </picture>
                    <div class="content_comments_commAuth__x9rNc">Bakkari</div>
                  </div>
                  <div>
                    <div class="content_comments_cHTomRight__35SLt">
                      <div>
                        <button class="content_comments_commBtn__eKEkm">
                          <i class="google-material-icons">more_vert</i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content_comments_ratingW__Icdsj">
                  <div class="StarRating_starList__vWMsK">
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                  </div>
                  <span class="content_comments_commDate__6Wi+t">
                    17 Februari 2025
                  </span>
                </div>
              </header>
              <div class="content_comments_commBW__Tqqwl">
                Michezo ya sloti ni rahisi kucheza, na bonasi nyingi
                zinapatikana mara kwa mara.
              </div>
              <div>
                <div class="content_comments_likeB__A+YQO">
                  84 watu waliona hili linafaida
                </div>
                <footer class="content_comments_likeF__27Irc">
                  <div class="content_comments_likeFInfo__zWZUk">
                    Maoni haya yamekusaidia?
                  </div>
                  <div>
                    <div class="content_comments_likeFBtnWrap__yjgrs">
                      <div class="content_comments_likeFBtn__9gQqp">
                        <div aria-hidden="true">
                          <span>Ndiyo</span>
                        </div>
                      </div>
                      <div class="content_comments_likeFBtn__9gQqp">
                        <div aria-hidden="true">
                          <span>Hapana</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
            <div class="content_comments_commW__KS6qP">
              <header>
                <div class="content_comments_cHTom__-3xre">
                  <div class="content_comments_cHTomLeft__Uz2TX">
                    <picture>
                      <source
                        srcset="/c37d8e719e13ca49f595f274bbbf4ee1.jpg"
                        type="image/webp"
                      />
                      <source
                        srcset="/c37d8e719e13ca49f595f274bbbf4ee1.jpg"
                        type="image/jpeg"
                      />
                      <img
                        class="content_comments_commAuthImg__VOqdH"
                        src="/c37d8e719e13ca49f595f274bbbf4ee1.jpg"
                        alt="auth"
                      />
                    </picture>
                    <div class="content_comments_commAuth__x9rNc">Hassan</div>
                  </div>
                  <div>
                    <div class="content_comments_cHTomRight__35SLt">
                      <div>
                        <button class="content_comments_commBtn__eKEkm">
                          <i class="google-material-icons">more_vert</i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content_comments_ratingW__Icdsj">
                  <div class="StarRating_starList__vWMsK">
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                  </div>
                  <span class="content_comments_commDate__6Wi+t">
                    16 Februari 2025
                  </span>
                </div>
              </header>
              <div class="content_comments_commBW__Tqqwl">
                Gal Sport Casino ina malipo ya haraka na salama. Hii ni nafasi
                nzuri ya kupata ushindi wa kweli.
              </div>
              <div>
                <div class="content_comments_likeB__A+YQO">
                  140 watu waliona hili linafaida
                </div>
                <footer class="content_comments_likeF__27Irc">
                  <div class="content_comments_likeFInfo__zWZUk">
                    Maoni haya yamekusaidia?
                  </div>
                  <div>
                    <div class="content_comments_likeFBtnWrap__yjgrs">
                      <div class="content_comments_likeFBtn__9gQqp">
                        <div aria-hidden="true">
                          <span>Ndiyo</span>
                        </div>
                      </div>
                      <div class="content_comments_likeFBtn__9gQqp">
                        <div aria-hidden="true">
                          <span>Hapana</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
            {/* <div class="content_comments_commW__KS6qP">
              <header>
                <div class="content_comments_cHTom__-3xre">
                  <div class="content_comments_cHTomLeft__Uz2TX">
                    <picture>
                      <source srcset="/_r64x64_.webp" type="image/webp" />
                      <source srcset="/_r64x64_.webp" type="image/jpeg" />
                      <img
                        class="content_comments_commAuthImg__VOqdH"
                        src="/_r64x64_.webp"
                        alt="auth"
                      />
                    </picture>
                    <div class="content_comments_commAuth__x9rNc">
                      Алексей Т.
                    </div>
                  </div>
                  <div>
                    <div class="content_comments_cHTomRight__35SLt">
                      <div>
                        <button class="content_comments_commBtn__eKEkm">
                          <i class="google-material-icons">more_vert</i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content_comments_ratingW__Icdsj">
                  <div class="StarRating_starList__vWMsK">
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                  </div>
                  <span class="content_comments_commDate__6Wi+t">
                    14 октября 2024 г.
                  </span>
                </div>
              </header>
              <div class="content_comments_commBW__Tqqwl">
                Для меня важно чувствовать, что игра честная, и это приложение
                не разочаровало. Я выиграл, и процесс вывода был простым и без
                проблем.
              </div>
              <div>
                <div class="content_comments_likeB__A+YQO">
                  171 человек отметили этот отзыв как полезный.
                </div>
                <footer class="content_comments_likeF__27Irc">
                  <div class="content_comments_likeFInfo__zWZUk">
                    Вам помогла эта информация?
                  </div>
                  <div>
                    <div class="content_comments_likeFBtnWrap__yjgrs">
                      <div class="content_comments_likeFBtn__9gQqp">
                        <div aria-hidden="true">
                          <span>Да</span>
                        </div>
                      </div>
                      <div class="content_comments_likeFBtn__9gQqp">
                        <div aria-hidden="true">
                          <span>Нет</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
            <div class="content_comments_commW__KS6qP">
              <header>
                <div class="content_comments_cHTom__-3xre">
                  <div class="content_comments_cHTomLeft__Uz2TX">
                    <picture>
                      <source srcset="/_r64x641_webp.webp" type="image/webp" />
                      <source srcset="/_r64x641_webp.webp" type="image/jpeg" />
                      <img
                        class="content_comments_commAuthImg__VOqdH"
                        src="/_r64x641_webp.webp"
                        alt="auth"
                      />
                    </picture>
                    <div class="content_comments_commAuth__x9rNc">
                      Дмитрий И.
                    </div>
                  </div>
                  <div>
                    <div class="content_comments_cHTomRight__35SLt">
                      <div>
                        <button class="content_comments_commBtn__eKEkm">
                          <i class="google-material-icons">more_vert</i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content_comments_ratingW__Icdsj">
                  <div class="StarRating_starList__vWMsK">
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemFill__+2irG">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                    <span
                      class="StarRating_starItem__+EBtP"
                      style={{ width: "12px", height: "12px" }}
                    >
                      <span class="StarRating_starItemNotFill__ymUQS">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                        </svg>
                      </span>
                    </span>
                  </div>
                  <span class="content_comments_commDate__6Wi+t">
                    10 октября 2024 г.
                  </span>
                </div>
              </header>
              <div class="content_comments_commBW__Tqqwl">
                Отличное казино. Даже с моим огромным опытом я нашел для себя
                новые слоты. Так что миф рухнул – я еще не видел всего.
                <br />
              </div>
              <div>
                <div class="content_comments_likeB__A+YQO">
                  112 человек отметили этот отзыв как полезный.
                </div>
                <footer class="content_comments_likeF__27Irc">
                  <div class="content_comments_likeFInfo__zWZUk">
                    Вам помогла эта информация?
                  </div>
                  <div>
                    <div class="content_comments_likeFBtnWrap__yjgrs">
                      <div class="content_comments_likeFBtn__9gQqp">
                        <div aria-hidden="true">
                          <span>Да</span>
                        </div>
                      </div>
                      <div class="content_comments_likeFBtn__9gQqp">
                        <div aria-hidden="true">
                          <span>Нет</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div> */}
            <div class="content_comments_commAWD3__DTQm-">
              <button>
                <span>Maoni yote</span>
              </button>
            </div>
          </section>
          <section class="DescSelector_sel__TiLso">
            <header class="DescSelector_header__kzxq+">
              <div class="DescSelector_hTop__iz3Wu">
                <div class="DescSelector_hTopT__lQ46T">
                  <h2>Nini kipya</h2>
                </div>
              </div>
            </header>
            <div class="DescSelector_content__vd-g4">
              <div></div>
            </div>
          </section>
          <section class="DescSelector_sel__TiLso">
            <header class="DescSelector_header__kzxq+">
              <div class="DescSelector_hTop__iz3Wu">
                <div class="DescSelector_hTopT__lQ46T">
                  <h2>Wasiliana na msanidi programu</h2>
                </div>
                <div>
                  <button class="DescSelector_hBtn__2tAOY">
                    <i
                      class="google-material-icons DescSelector_hBtnIcon__lZHve"
                      aria-hidden="true"
                    >
                      expand_more
                    </i>
                  </button>
                </div>
              </div>
            </header>
            <div class="DescSelector_content__vd-g4">
              <div
                class="content_contact_wrapper__+9fi3"
                style={{ display: "none" }}
              ></div>
            </div>
          </section>
        </div>
      </div>
      <div class="footer_wrapper__3VsCj">
        <div class="footer_line__RKj3e"></div>
      </div>
    </>
  );
};

export default Content;
